<template>
<div class="ring text-center">
  <h3>Incoming Call</h3>
  <div class="body">
    <font-awesome-icon icon="phone" :style="{ color: 'white' }" size="8x"/>
  </div>
  <br/>
  <button class="btn btn-large btn-primary mt-3" @click="pressAnswer">Answer</button>
</div>
</template>

<script>
import { getLog } from "../services/log"
let log = getLog('ring');

export default {
  name: 'ring',
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      audio: null
    }
  },
  mounted() {
    if (this.$parent.debugMuted) {
      log.log("Ring sound muted");
      return;
    }
    try {
      this.audio = new Audio(require("../assets/ding.mp3"));
      this.audio.loop = true;
      this.audio.play().catch(error => {
        log.log("Playing failed...", error);
      });
    }
    catch (err) {
      log.log("playRingSound error", err);
    }
  },
  beforeDestroy() {
    if (this.audio)
      this.audio.pause();
  },
  methods: {
    pressAnswer() {
      if (this.audio)
        this.audio.pause();
      this.$emit('close-page');
    }
  }
}
</script>

<style scoped>

.body {
  display: inline-block;
  background-color: rgb(130, 248, 130);
  height: 300px;
  width: 300px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-top: 80px;
  margin-top: 60px;
  margin-bottom: 60px;
}

</style>